@import './daypicker';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: Roboto;
  background-color: #2b2e38;
  overflow-y: scroll;
}
