.fill-grid {
  height: 100%;
}

.small-grid {
  height: 300px;
}

.big-grid {
  height: 600px;
}
